import React, { memo as Memo, useMemo, useRef, useCallback, useEffect, useState } from 'react';

//* HOC's
import withDataContext from '@context/consumerHOC/DataConsumer'
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Components
import Page from '@components/global/Page'
import HTMLContainer from '@components/global/HTMLContainer'
import AwardsCont from '@components/global/AwardsCont'
import PortfolioShortList from '@components/global/PortfolioShortList'
import HomeTop from '@components/pages/Home/HomeTop'
import WhatWeDo from '@components/pages/Home/WhatWeDo'
import AnimationWrap from '@components/global/AnimationWrap'
import Testimonials from '@components/global/Testimonials'


const Home = Memo(({ pages, selectedLang, pageParams, winHeight, ...props }) => {

    //! State
    const [secObj1, setSecObj1] = useState()
    const [secObj, setSecObj] = useState({
        sec1: false,
    })

    //! Page data
    const pageData = useMemo(() => pages[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

    //! Ref's
    const sec1 = useRef();

    //! Handle Active Section Animation
    const Anim = useCallback((x) => {
        if (JSON.stringify(x) !== JSON.stringify(secObj1)) {
            setSecObj1(x)
        }
    }, [secObj1])

    const sectionAnimation = useCallback(() => {
        let arr = []

        if (sec1.current) {
            arr = [
                sec1.current.getBoundingClientRect(),
            ]

            let newObj = secObj;
            for (let i = 0; i < arr.length; i++) {
                newObj = { ...newObj, [`sec${i + 1}`]: arr[i].top < winHeight }
            }

            if (JSON.stringify(newObj) !== JSON.stringify(secObj)) {
                setSecObj(newObj)
            }
        }
    }, [sec1, secObj])

    useEffect(() => {
        window.addEventListener('scroll', sectionAnimation)

        return () => {
            window.removeEventListener('scroll', sectionAnimation)
        }
    }, [sectionAnimation])

    const awardsProps = useMemo(() => {
        let obj = pageData && {
            parentClassName: "crStick",
            link: props.config.awards.path,
            data: {
                title: pageData.awards.title,
                subtitle: pageData.awards.subtitle,
                description: pageData.awards.description,
                settings: pageData.awards.settings
            },
            count: 4,
            anime: secObj.sec1
        }

        return props.global ? { ...obj, global: props.global } : obj
    }, [props.config.awards.path, pageData, props.global, secObj])

    const schema = useMemo(() => (
        `{
            "@context": "http://schema.org/",
            "@type": "Organization",
            "name": "Concept Studio",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "V. Antarain 11, 0009",
                "addressLocality": "Yerevan",
                "addressRegion": "Yerevan",
                "postalCode": "0051"
            },
            "telephone": "+374 11 20 2311"
        }`
    ), [])

    return (
        <Page className="homePage" meta={pageData?.meta} schema={schema} footer={props?.footer}>
            {pageData && (
                <>
                    <HTMLContainer full>

                        <div className="section-item crStar">
                            <HomeTop data={{
                                title: pageData.hero.title,
                                subtitle: pageData.hero.subtitle,
                                description: pageData.hero.description
                            }} config={props.config} />
                        </div>

                        <AnimationWrap state={Anim}>
                            <div className="section-item crCircle">
                                <WhatWeDo active={secObj1?.sec1} data={{
                                    title: pageData.what_we_do.title,
                                    subtitle: pageData.what_we_do.subtitle,
                                    description: pageData.what_we_do.description,
                                    buttonUrl: props.config.services.path
                                }} />
                            </div>

                            <div className="section-item">
                                <PortfolioShortList active={secObj1?.sec2} link={props.config.portfolio.path} title={pageData.works.title} />
                            </div>
                        </AnimationWrap>


                    </HTMLContainer>

                    <div ref={sec1}>
                        <AwardsCont {...awardsProps} />
                    </div>

                    {pageData.testimonials?.length ? (
                        <Testimonials
                            title={'testimo</br>nials'}
                            items={pageData.testimonials}
                        />
                    ) : null}
                </>
            )}
        </Page>
    )
})

export default withUIContext(withDataContext(Home, ['pages']), ['winHeight'])

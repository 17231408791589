import React, { memo as Memo, useMemo, Fragment, useRef, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { gsap } from 'gsap'

//* Components
import MinTitle from '@components/global/MinTitle'
import Text from '@components/global/Text'
import Translate from '@components/global/Translate'
import Button from '@components/global/Button'

//* Helpers
import breakString from '@helpers/breakString';

//* Style 
import style from './style.module.scss'

//* SVG
const CharactersBalance = dynamic(import('../../../global/SvgComponents/CharactersBalance'), { ssr: false })

const WhatWeDo = Memo(({ data, active }) => {

    //! ref
    const topRef = useRef()

    //! Animation
    const tl = useMemo(() => gsap.timeline({ delay: 0.8 }), [])

    useEffect(() => {

        const arr = [topRef.current.childNodes[0].childNodes, topRef.current.childNodes[1]]
        if (active) {
            tl.staggerTo(
                [arr], 0, {
                opacity: 1
            }, 0.2);
        }
        else {
            tl.staggerTo(
                [arr], 0, {
                opacity: 0
            }, 0)

        }
    }, [active])

    const chars = useMemo(() => (
        <CharactersBalance />
    ), [])

    return (
        <Fragment>

            <MinTitle tag="p" active={active} title={breakString(data.title)} />

            <div className={style.section_cont} ref={topRef}>
                <div className={style.top_section_info_wrapp}>

                    <Text tag={"h1"} className={`h4 font-poppins font-light ${style.subtitle}`}>
                        {data.subtitle}
                    </Text>

                    <Text tag={"p"} className={`p-l font-poppins font-light ${style.description}`}>
                        {data.description}
                    </Text>

                    <Button
                        className={`${style.description}`}
                        otherLink={{
                            pathname: data.buttonUrl,
                        }}
                        text={<Translate val={"ourServices"} />}
                    />

                </div>


                <div className={style.home_animation_element_img}>
                    <div className={style.home_animation_element_img_inner}>
                        {chars}
                    </div>
                </div>
            </div>

        </Fragment>
    )
})

export default WhatWeDo;
import React, { memo as Memo, useMemo, useEffect, Fragment, useRef, useCallback, useState } from 'react'
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'
import { gsap, TimelineMax, Power3 } from 'gsap'
import TextPlugin from "gsap/dist/TextPlugin"

//* HOC's
import withLanguageContext from '@context/consumerHOC/LanguageConsumer';
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Components
import Text from '@components/global/Text'
import Translate from '@components/global/Translate';
import CustomLink from '@components/global/CustomLink';
const SocIcon = dynamic(import('../../../global/SocIcon'), { ssr: false })

//* SVG
const ZiggyHomeTop = dynamic(import('../../../global/SvgComponents/ZiggyHomeTop'), { ssr: false })

//* Helpers
import breakString from '@helpers/breakString'

//* Style 
import style from './style.module.scss'

gsap.registerPlugin(TextPlugin)

const HomeTop = Memo(({ data, translate, config, router, winWidth, screenSizes }) => {

    //! Ref's
    const topRef1 = useRef()
    const topRef2 = useRef()
    const svgRef = useRef()
    const textRef = useRef()

    //! States
    const [play, setPlay] = useState(true)

    //! Animation
    const tl = useMemo(() => new TimelineMax({ paused: true }), [])

    let active = 1
    const textAnim = useMemo(() => new TimelineMax({
        repeat: -1,
        paused: true,
        onRepeat: () => {
            textAnim.set(textRef.current, {
                text: () => {
                    active = getRandomString(active)
                    return translate(`startJourneyMiddleTextLine3_${active}`)
                }
            }, 0)
        }
    }), [])

    //! Component Did Mount Listener
    useEffect(() => {
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    //! Scroll Listener
    const onScroll = useCallback(() => {
        const el = topRef2?.current?.getBoundingClientRect()
        el && setPlay(el.y > -el.height && el.y < window.innerHeight)
    }, [])

    //! Enable/Disable Animation
    useEffect(() => {
        if (play) {
            textAnim.play()
            tl.play()
        }
        else {
            textAnim.pause()
            tl.pause()
        }
    }, [play])

    //! Elements Animation
    useEffect(() => {
        tl.clear().pause()

        tl
            .staggerTo(topRef1.current.childNodes, 0, {
                opacity: 1
            }, 0.1)
            .to(topRef1.current.childNodes[1], 0, {
                css: { fontStyle: 'italic', fontWeight: 300 }
            }, 0.2)

        const el = winWidth <= 768 ? topRef2.current.childNodes : [topRef2.current.childNodes[0].childNodes, topRef2.current.childNodes[1]]

        tl.staggerTo(
            el, 0, {
            delay: 0.1,
            opacity: 1
        }, 0.1)

        //! SVG Animation
        tl.fromTo(svgRef.current, 1.5, {
            transformOrigin: '155 155', rotation: -25
        },
            {
                transformOrigin: '155 155', rotation: 20, repeat: -1, yoyo: true, ease: Power3.easeInOut
            }, 0)

        tl.play()

        //! Text Change Effect
        textAnim.set(textRef.current, {
            text: () => {
                active = getRandomString(active)
                return translate(`startJourneyMiddleTextLine3_${active}`)
            }
        }, 0.4);

        return () => {
            tl.clear().kill()
            textAnim.clear().kill()
        }
    }, [winWidth])

    //! Get Random String
    const getRandomString = useCallback((active) => {
        let newNum = active

        do {
            newNum = Math.round(Math.random() * 2) + 1
        }
        while (newNum === active)

        return newNum
    }, [])

    const titleCont = useMemo(() => (
        <div className={style.title}>
            <Text tag={"h2"} className={"h1L font-poppins font-semi-bold"} ref={topRef1}>
                {breakString(data.title)}
            </Text>
        </div>
    ), [data])

    const subtitleCont = useMemo(() => (
        <Text tag={"p"} className={`p-l font-anonymous font-regular ${style.subtitle}`}>
            {data.subtitle}
        </Text>
    ), [data])

    const descriptionCont = useMemo(() => (
        <Text className={`h4 font-poppins font-light ${style.description}`}>
            {data.description}
        </Text>
    ), [data])

    const socIconCont = useMemo(() => (
        <div className={style.soc_icon_container}>
            <SocIcon type={2} menuIcon={true} isSocial={true} />
        </div>
    ), [])

    const animationCont = useMemo(() => (
        <div className={style.home_animation_element_img}>
            <ZiggyHomeTop />
        </div>
    ), [])

    const animationTextCont = useMemo(() => (
        <CustomLink url={config["contact-form"].path} className={style.home_animation_element_text_wrapp}>
            <div className={`${style.home_animation_element_top} ${router.locale === 'de' ? style.home_animation_element_top_ls : ''}`}>
                <Text tag={"p"} className={"font-anonymous font-regular"} text={translate('startJourneyMiddleText')} >
                    <span><Translate val={"startJourneyMiddleTextLine1"} /></span>
                    <span><Translate val={"startJourneyMiddleTextLine2"} /></span>
                    <span>
                        <strong className={`font-italic font-bold`} ref={textRef}>
                            <Translate val={"startJourneyMiddleTextLine3_1"} />
                        </strong>

                        <Translate val={"startJourneyMiddleTextLine3"} />
                    </span>
                </Text>
            </div>

            <div className={`${style.home_animation_element_bottom} ${router.locale === 'de' ? style.home_animation_element_bottom_ls : ''}`}>
                <svg ref={svgRef} className={`circle-svg ${style.circle_svg}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
                    <defs>
                        <path id={`circlePath`} className={`circle ${style.circle}`} d="M2.1,196.1c19.5,48.1,80.4,97.3,153.8,97.3c74.1,0,135.1-46.1,154.2-98.3" />
                    </defs>
                    <text>
                        <textPath className={`font-anonymous font-bold ${style.circle_text}`} xlinkHref="#circlePath" id="masterTextPath" >{translate('startYourJurney')}</textPath>
                    </text>
                </svg>
            </div>
        </CustomLink>
    ), [router.locale])

    const infoDesktopCont = useMemo(() => (
        <Fragment>
            <div className={style.top_section_info_wrapp}>
                {subtitleCont}
                {descriptionCont}
                {socIconCont}
            </div>
            <div className={style.home_animation_element_wrapp}>
                {animationCont}
                {animationTextCont}
            </div>
        </Fragment>
    ), [titleCont, subtitleCont, descriptionCont, animationCont, animationTextCont])

    const infoMobileCont = useMemo(() => (
        <Fragment>
            {subtitleCont}
            <div className={style.home_animation_element_wrapp}>
                {animationCont}
            </div>
            {socIconCont}
            {descriptionCont}
            <div className={style.home_animation_element_wrapp}>
                {animationTextCont}
            </div>
        </Fragment>
    ), [titleCont, subtitleCont, descriptionCont, animationCont, animationTextCont])

    const blockHTML = useMemo(() => {
        return (
            <Fragment>
                {titleCont}
                <div className={style.top_section_wrapp} ref={topRef2}>
                    {winWidth >= screenSizes.screenXS ? infoDesktopCont : infoMobileCont}
                </div>
            </Fragment>
        )
    }, [infoMobileCont, infoDesktopCont, winWidth, screenSizes.screenXS])

    return blockHTML
})

export default withRouter(withLanguageContext(withUIContext(HomeTop, ['screenSizes', 'winWidth']), ['translate']));